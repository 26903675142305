import React, { createContext, useContext, useState } from "react";
import axios from "axios";

export const UserDataContext = createContext({
  userData: null,
  updateUserData: () => {},
});

export const useUserData = () => useContext(UserDataContext);

export const UserDataProvider = ({ children }) => {
  const [userData, setUserData] = useState({
    id: "",
    username: "",
    solarEdgeToken: "",
    solarmanToken: "",
    solarmanEmail: "",
    solarmanPassword: "",
    goodweEmail: "",
    goodwePassword: "",
    goodwePowerStationId: "",
    foxessKey: "",
    growattUser: "",
    growattPassword: "",
    fusionSolarUser: "",
    fusionSolarPassword: "",
  });

  const updateUserData = (newData) => {
    setUserData({ ...userData, ...newData });
  };

  return (
    <UserDataContext.Provider value={{ userData, updateUserData }}>
      {children}
    </UserDataContext.Provider>
  );
};
