import React, { useState, useEffect } from "react";
import axios from "axios";
import Chart from "react-apexcharts";

import LoadingSpinner from "../components/LoadingSpinner";
import "../App.css";
import { useUserData, UserDataContext } from "../context/UserDataContext";

const Goodwe = () => {
  const [sitePower, setSitePower] = useState();
  const { userData } = useUserData();
  const [token, setToken] = useState();
  const [uid, setUID] = useState();
  const [timestamp, setTimestamp] = useState();
  const [apiUrl, setApiUrl] = useState();
  const [address, setAddress] = useState();
  const [powerStationType, setPowerStationType] = useState();
  const [monthGeneration, setMonthGeneration] = useState();
  const [totalPower, setTotalPower] = useState();
  const [capacity, setCapacity] = useState();
  const [visible, setVisilbe] = useState(false);
  const [data, setData] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [alert, showAlert] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData.goodweEmail != null) {
      if (!token && !uid) {
        setLoading(true);
        loginApiGoodwe();
      }
    } else {
      showAlert(true);
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (data.info) {
        setPowerStationType(data.info.address);
        setCapacity(data.info.capacity);
      }

      if (data.kpi) {
        setMonthGeneration(data.kpi.month_generation);
        setTotalPower(data.kpi.total_power);
      }
    }
  }, [data]);

  const loginApiGoodwe = () => {
    console.log("dane", userData.goodweEmail, userData.goodwePassword);
    axios
      .post(`${process.env.REACT_APP_API_URL}api/goodwe/Login`, {
        account: userData.goodweEmail,
        password: userData.goodwePassword,
      })
      .then((response) => {
        const { token, uid, timestamp } = response.data.data;
        const apiUrl = response.data.api;

        // Update state
        setToken(token);
        setUID(uid);
        setTimestamp(timestamp);
        setApiUrl(apiUrl);
        console.log("A");
        getPlantList(apiUrl, token, uid, timestamp);
        return getMonitorDetailByPowerstationId(apiUrl, token, uid, timestamp);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getMonitorDetailByPowerstationId = (api, token, uid, timestamp) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/goodwe/PowerStation?powerStationId=${userData.goodwePowerStationId}&timestamp=${timestamp}&uid=${uid}&token=${token}&api=${api}`,
        {
          powerStationId: userData.goodwePowerStationId,
        }
      )
      .then((response) => {
        if (response.data) {
          setData(response.data.data);
          // TODO Here is the issue
          return getDataChart(api, token, uid, timestamp);
        }
        setTimeout(() => {
          setVisilbe(true);
          console.log(address);
        }, 1000);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getPlantList = (api, token, uid, timestamp) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/goodwe/GetPlantList?timestamp=${timestamp}&uid=${uid}&token=${token}&api=${api}`
      )
      .then((response) => {
        console.log("Plant list", response.data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const getDataChart = (api, token, uid, timestamp) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/goodwe/GetChartData?powerStationId=${userData.goodwePowerStationId}&timestamp=${timestamp}&uid=${uid}&token=${token}&api=${api}`
      )
      .then((response) => {
        const chartData = response.data.data;
        if (chartData && chartData.lines) {
          setDataChart(response.data.data.lines);
          console.log("Hook", response.data.data.lines[0].xy);
          setShowChart(true);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div>
      {alert && (
        <div styles={{}}>
          Nie znaleziono danych wymaganych do połączenia z serwisem Goodwe.
          Proszę przejść do swojego profilu i uzupełnić informacje dotyczące
          konta.
        </div>
      )}
      {!alert && data && (
        <>
          <div>PowerStationId: {userData.goodwePowerStationId}</div>
          <div>Adres instalacji:{data.size > 0 ? data.data.address : ""}</div>
          <div>Typ instalacji: {powerStationType}</div>
          <div>Produkcja miesięczna: {monthGeneration}kW</div>
          <div>Wyprodukowano od początku: {totalPower}kW</div>
          <div>Wielkość instalacji: {capacity}kW</div>
        </>
      )}
      <LoadingSpinner isLoading={loading} />
      {showChart && (
        <div>
          <Chart
            options={{
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                categories: dataChart[0].xy.map((item) => item.x),
              },
            }}
            series={[
              {
                name: "series-1",
                data: dataChart[0].xy.map((item) => item.y),
              },
            ]}
            type="line"
            style={{ backgroundColor: "white" }}
            width={500}
            height={320}
          />
        </div>
      )}
    </div>
  );
};

export default Goodwe;
