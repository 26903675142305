import React, { useEffect, useState } from "react";
import axios from "axios";

const VictronEnergy = () => {
  return (
    <div>
      <h1>Victron Energy</h1>
    </div>
  );
};

export default VictronEnergy;
