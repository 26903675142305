import axios from "axios";

export const fusionSolarSetupCredentials = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}api/fusionSolar/login`
  );
};

export const fusionSolarGetStationList = async (cookie) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}api/fusionSolar/stations?cookie=${cookie}`
  );
};

export const fusionSolarPlantDetails = async (cookie, plantCode) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}api/fusionSolar/stationDetails?cookie=${cookie}&plantCode=${plantCode}`
  );
};

export const fusionSolarHistoryData = async (cookie, plantCode) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}api/fusionSolar/historical?cookie=${cookie}&plantCode=${plantCode}`
  );
};
