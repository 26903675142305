import * as React from "react";
import { Link } from "react-router-dom";
import "./Layout.css";
import { Outlet } from "react-router-dom";
import { HandIndexThumbFill } from "react-bootstrap-icons";

export default function Layout() {
  return (
    <>
      <div id="sidebar">
        <h1>Monitoring fotowoltaiczny</h1>
        <nav>
          <ul>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/profil">Profil</Link>
            </li>
            <li>
              <Link to="/solaredge">
                SolarEdge <HandIndexThumbFill />
              </Link>
            </li>
            <li>
              <Link to="/sofarsolar">
                SolarSofar
                <HandIndexThumbFill />
              </Link>
            </li>
            <li>
              <Link to="/foxess">
                FoxEss
                <HandIndexThumbFill />
              </Link>
            </li>
            <li>
              <Link to="/goodwe">
                Goodwe
                <HandIndexThumbFill />
              </Link>
            </li>
            <li>
              <Link to="/growatt">Growatt</Link>
            </li>
            <li>
              <Link to="/huawei">
                Huawei <HandIndexThumbFill />
              </Link>
            </li>
            <li>
              <Link to="/fronius">Fronius</Link>
            </li>
            <li>
              <Link to="/victron">Victron Energy</Link>
            </li>
            <li>
              <Link to="/logout">Logout</Link>
            </li>
          </ul>
        </nav>
      </div>
      <div id="detail">
        <Outlet />
      </div>
    </>
  );
}
