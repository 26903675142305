import React, { useState, useEffect } from "react";
import "./Login.css";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import appImage from "../images/monitoring.png";

const Login = () => {
  const { isAuthenticated, handleLogin } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const onLoginClick = () => {
    handleLogin(username, password)
      .then(() => {
        if (isAuthenticated) {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        setError(error); // Wyświetlanie błędu jako alert
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate]);

  return (
    <>
      <div className="login-container">
        <h2>Logowanie do monitoringu</h2>
        <form>
          <div>
            <label>Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="button" onClick={onLoginClick}>
            Log In
          </button>
        </form>
        {error && <div className="error">{error}</div>}
        <img src={appImage} style={{ width: 240, marginTop: 40 }} />
      </div>
    </>
  );
};

export default Login;
