import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import "./App.css";
import ErrorPage from "./pages/ErrorPage";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Profil from "./pages/Profil";
import RecoveryPassword from "./pages/RecoveryPassword";
import Layout from "./routes/Layout";
import SolarEdge from "./pagesInterfaces/SolarEdge";
import FoxEss from "./pagesInterfaces/FoxEss";
import Growatt from "./pagesInterfaces/Growatt";
import SolarSofar from "./pagesInterfaces/SolarSofar";
import Huawei from "./pagesInterfaces/Huawei";
import Fronius from "./pagesInterfaces/Fronius";
import Goodwe from "./pagesInterfaces/Goodwe";
import VictronEnergy from "./pagesInterfaces/VictronEnergy";
import InstallationDetails from "./components/InstallationDetails";

import { useAuth } from "./context/AuthContext";

const App = () => {
  const { isAuthenticated } = useAuth();
  //zapis do localstorage
  //hook do pobrania tokenu z localstorage jak istnieje jak nie
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="recovery-password" element={<RecoveryPassword />} />

        {/* Wrap all private routes within Layout */}
        <Route
          path="/"
          element={isAuthenticated ? <Layout /> : <Navigate to="/login" />}
        >
          <Route index element={<Dashboard />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="profil" element={<Profil />} />
          <Route path="solaredge" element={<SolarEdge />} />
          <Route path="foxess" element={<FoxEss />} />
          <Route path="growatt" element={<Growatt />} />
          <Route path="goodwe" element={<Goodwe />} />
          <Route path="sofarsolar" element={<SolarSofar />} />
          <Route path="huawei" element={<Huawei />} />
          <Route path="fronius" element={<Fronius />} />
          <Route path="victron" element={<VictronEnergy />} />
          <Route
            path="installations/:type/:id"
            element={<InstallationDetails />}
          />
          <Route path="logout" element={<Logout />} />
        </Route>

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
