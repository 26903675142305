import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import LoadingSpinner from "../components/LoadingSpinner";
import {
  getPlantListFoxess,
  plantDetailsFoxess,
  getPlantPowerGenerationsFoxess,
  getPlantHistory,
  getPlantRealTimeData,
} from "../utils/foxessUtils";
import "../css/FoxEss.css";

const FoxESS = ({ siteId }) => {
  const [plantList, setPlantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [plantDetails, setPlantDetails] = useState(null);
  const [powerGenerations, setPowerGenerations] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [realTimeData, setRealTimeData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const key = "aa6d5747-4ed6-4a46-bd3f-d291053a281a";

  useEffect(() => {
    setLoading(true);
    getDataFromFoxess();
    if (siteId) {
      getPlantDetails(siteId);
    }
  }, [siteId]);

  useEffect(() => {
    const processData = (data) => {
      return data
        .map((entry) => {
          const pvPowerData = entry.datas.find(
            (data) => data.variable === "pvPower"
          );

          const extractTime = (dateTimeStr) => {
            const formattedDateTimeStr = dateTimeStr
              .replace(" CEST", "")
              .replace("+0200", "");
            const date = new Date(formattedDateTimeStr);
            return date;
          };

          const xValue = extractTime(entry.time);
          const yValue = pvPowerData ? pvPowerData.value : 0;

          if (!isNaN(yValue)) {
            return {
              x: xValue,
              y: yValue,
            };
          } else {
            return null;
          }
        })
        .filter((data) => data !== null);
    };

    if (realTimeData.length > 0) {
      const processedData = processData(realTimeData);
      console.log("Dane pocięte", processedData);
      setSeriesData(processedData);
    }
  }, [realTimeData]);

  const getDataFromFoxess = async () => {
    try {
      const response = await getPlantListFoxess(key, key);
      setPlantList(response.data.result.data);
      setLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
      setLoading(false);
    }
  };

  const getPlantDetails = async (plantID) => {
    console.log("Dlant id details", plantID);
    try {
      setLoading(true);
      const response = await plantDetailsFoxess(plantID);
      setPlantDetails(response.data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("There was an error!");
      console.error("There was an error!", error);
    }
  };

  const getPlantPowerGenerations = async (sn) => {
    try {
      setLoading(true);
      const response = await getPlantPowerGenerationsFoxess(sn);
      setPowerGenerations(response.data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("There was an error!");
      console.error("There was an error!", error);
    }
  };

  const getHistory = async (plantID, startDate, endDate) => {
    try {
      setLoading(true);
      const response = await getPlantHistory(plantID, startDate, endDate);
      console.log("History data", response.data);
      setHistoryData(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("There was an error!");
      console.error("There was an error!", error);
    }
  };

  const getRealTime = async (plantID) => {
    try {
      setLoading(true);
      const response = await getPlantRealTimeData(plantID);
      console.log(response.data.result);
      setRealTimeData(response.data.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("There was an error!");
      console.error("There was an error!", error);
    }
  };

  const options = {
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    title: {
      text: "PV Power Over Time",
      align: "center",
    },
    xaxis: {
      type: "datetime", // Użyj 'datetime', jeśli wartości x są obiektami Date
      title: {
        text: "Time",
      },
    },
    yaxis: {
      title: {
        text: "PV Power (kW)",
      },
    },
    stroke: {
      curve: "smooth",
    },
    markers: {
      size: 5,
    },
  };

  const series = [
    {
      name: "PV Power",
      data: seriesData.map((data) => [data.x.getTime(), data.y]), // Konwertuj Date do milisekund
    },
  ];

  const updatedList =
    plantList &&
    plantList.map((plant, index) => (
      <li key={index}>
        Instalacja: {plant.name} Stacji: {plant.stationID}
        <button onClick={() => getPlantDetails(plant.stationID)}>Detale</button>
      </li>
    ));

  const startDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const endDate = new Date();

  return (
    <div id="FoxEss">
      <LoadingSpinner isLoading={loading} />
      <header className="FoxESS-header">FoxEss monitoring</header>
      {plantDetails && (
        <div id="detale" style={{ marginBottom: "10px" }}>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Nazwa instalacji:</strong>
                </td>
                <td>{plantDetails.stationName}</td>
              </tr>
              <tr>
                <td>
                  <strong>Numer seryjny:</strong>
                </td>
                <td>{plantDetails.modules[0].deviceSN}</td>
              </tr>
              <tr>
                <td>
                  <strong>Wielkość instalacji:</strong>
                </td>
                <td>{plantDetails.capacity} kW</td>
              </tr>
            </tbody>
          </table>
          <div style={{ marginTop: "10px" }}>
            <button
              onClick={() =>
                getPlantPowerGenerations(plantDetails.modules[0].deviceSN)
              }
            >
              Produkcje
            </button>
            <button
              style={{ marginLeft: "10px" }}
              onClick={() =>
                getHistory(plantDetails.modules[0].deviceSN, startDate, endDate)
              }
            >
              Historia
            </button>
            <button
              style={{ marginLeft: "10px" }}
              onClick={() => getRealTime(plantDetails.modules[0].deviceSN)}
            >
              Real Time Data
            </button>
            {seriesData.length > 0 && (
              <Chart
                options={options}
                series={series}
                type="line"
                height={350}
              />
            )}
          </div>
          {powerGenerations && (
            <table style={{ marginTop: "10px" }}>
              <tbody>
                <tr>
                  <td>
                    <strong>Produkcja miesięczna:</strong>
                  </td>
                  <td>{powerGenerations.month} kW</td>
                </tr>
                <tr>
                  <td>
                    <strong>Produkcja dziś:</strong>
                  </td>
                  <td>{powerGenerations.today} kW}</td>
                </tr>
                <tr>
                  <td>
                    <strong>Produkcja całkowita:</strong>
                  </td>
                  <td>{powerGenerations.cumulative} kW</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
      <ul>{updatedList}</ul>
    </div>
  );
};

export default FoxESS;
