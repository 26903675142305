import React, { useState, useEffect } from "react";
import { getSolarEdgePlants, getPower } from "../utils/solarEdgeUtils";
import "../css/InstalationsList.css";
import LoadingSpinner from "../components/LoadingSpinner";
import { getPlantListFoxess } from "../utils/foxessUtils";
import {
  fusionSolarSetupCredentials,
  fusionSolarGetStationList,
} from "../utils/huaweiUtils";
import { Link } from "react-router-dom";

const InstalationsList = ({ onTotalPeakPowerChange }) => {
  const [solaredgeInstalations, setSolarEdgeInstalations] = useState([]);
  const [info, setInfo] = useState(false);
  const [cookie, setCookie] = useState(null);
  const [loading, setLoading] = useState(false);
  const [foxplantList, setFoxPlantList] = useState(); // Foxess
  const [huaweiList, setHuawei] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("solarEdgeToken") === null) {
      setInfo(true);
    } else {
      callForSolarEdge();
      callForFoxEss();
      callHuawei();
    }
  }, []);

  //zliczanie mocy zaznaczonych instalacji
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    const newTotal = Object.keys(checkedItems).reduce((sum, id) => {
      if (checkedItems[id]) {
        const installation = solaredgeInstalations.find(
          (item) => item.id === parseInt(id)
        );
        return sum + installation.peakPower;
      }
      return sum;
    }, 0);

    onTotalPeakPowerChange(newTotal);
  }, [checkedItems, solaredgeInstalations, onTotalPeakPowerChange]);

  const handleCheckboxChange = (se) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [se.id]: !prevState[se.id],
    }));
  };

  const apiKey = localStorage.getItem("solarEdgeToken");

  const callForSolarEdge = () => {
    setLoading(true);
    getSolarEdgePlants(apiKey)
      .then((response) => {
        setSolarEdgeInstalations(response.data.sites.site);
        // getProduction();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callForFoxEss = async () => {
    try {
      const response = await getPlantListFoxess();
      console.log("FoxEss", response.data.result.data);
      setFoxPlantList(response.data.result.data);
      setLoading(false);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const callHuawei = async () => {
    try {
      const response = await fusionSolarSetupCredentials();
      console.log("cookie", response.data.token);
      let cookie = response.data.token;
      setCookie(cookie);
      if (response.data.token != null) {
        try {
          const response = await fusionSolarGetStationList(cookie);
          console.log("Get Station List", response.data);
          setHuawei(response.data.data.list);
        } catch (error) {
          console.error("There was an error!", error);
        }
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <div id="InstalationList">
      <LoadingSpinner loading={loading} />
      <div
        className="box"
        style={{ width: "95%", minHeight: "300px", margin: 20 }}
      >
        <div className="savings-widget-title">
          Wybór instalacji do kalkulacji
        </div>
        {info && "Brak tokena do SolarEdge"}
        {loading ? (
          <div>Loading...</div>
        ) : (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr style={{ backgroundColor: "#f2f2f2", textAlign: "left" }}>
                <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                  Link
                </th>
                <th style={{ padding: "8px", border: "1px solid #ddd" }}>ID</th>
                <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                  Nazwa instalacji
                </th>
                <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                  Produkcja
                </th>
                <th style={{ padding: "8px", border: "1px solid #ddd" }}>
                  Wybór
                </th>
              </tr>
            </thead>
            <tbody>
              {solaredgeInstalations.map((se, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/installations/solarEdge/${se.id}`}>Wiecej</Link>
                  </td>
                  <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                    {se.id}
                  </td>
                  <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                    {se.name}
                  </td>
                  <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                    {se.peakPower}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={!!checkedItems[se.id]}
                      onChange={() => handleCheckboxChange(se)}
                    />
                  </td>
                </tr>
              ))}
              {foxplantList &&
                foxplantList.map((fe, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/installations/foxEss/${fe.stationID}`}>
                        View Details
                      </Link>
                    </td>
                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                      {fe.stationID}
                    </td>
                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                      {fe.name}
                    </td>
                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                      NONE
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                ))}

              {huaweiList &&
                huaweiList.map((hu, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/installations/huawei/${hu.plantCode}`}>
                        View Details
                      </Link>
                    </td>
                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                      {hu.plantCode}
                    </td>
                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                      {hu.plantName}
                    </td>
                    <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                      NONE
                    </td>
                    <td>
                      <input type="checkbox" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default InstalationsList;
