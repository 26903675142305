import React, { useState, useEffect } from "react";
import axios from "axios";
import { useUserData } from "../context/UserDataContext";

const SolarSofar = () => {
  const [access_token, setAccessToken] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [data, setData] = useState();
  const { userData } = useUserData();

  const solarToken = userData.solarmanToken;
  const solarmanEmail = userData.solarmanEmail;
  const solarmanPassword = userData.solarmanPassword;

  useEffect(() => {
    loginSolarSofarAPI();
  }, []);

  const loginSolarSofarAPI = () => {
    const postData = {
      appSecret: solarToken,
      email: solarmanEmail,
      password: solarmanPassword,
    };

    //Thats good
    axios
      .get(`${process.env.REACT_APP_API_URL}api/solarsofar/login`)
      .then((response) => {
        console.log("Dane po zalogowaniu", response.data);
        const token = response.data.access_token;
        setAccessToken(response.data.access_token);
        return callForData(token);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const callForData = (accessToken) => {
    console.log("callForData", accessToken);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/solarsofar/currentData?access_token=${accessToken}`
      )
      .then((response) => {
        console.log("API 2 call", response.data);
        setData(response.data);
        if (response.data.success == false) {
          setErrorMessage(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const callForHistorical = (accessToken) => {
    console.log("callForHistorical", accessToken);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/solarsofar/historical?access_token=${accessToken}`
      )
      .then((response) => {
        console.log("API Historical data", response.data);

        if (response.data.success == false) {
          setErrorMessage(response.data.msg);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div className="Se">
      <header className="Se-header">Monitoring Solar Sofar Solarman</header>
      <h4>{access_token ? "Api connected" : "Error"}</h4>
      <h4>{errorMessage}</h4>

      {/* <h4>{data.dataList}</h4> */}
      <h4>Numer SN urządzenia :{data && data.deviceSn}</h4>
      {/* <button onClick={() => callForHistorical(access_token)}>
        Pobierz dane historyczne
      </button> */}
      {data && (
        <>
          <h1>Device Data</h1>
          <p>Device SN: {data.deviceSn}</p>
          <p>Device Type: {data.deviceType}</p>
          <p>Device State: {data.deviceState}</p>
          <p>Request ID: {data.requestId}</p>
          <p>
            Collection Time:{" "}
            {new Date(data.collectionTime * 1000).toLocaleString()}
          </p>
          <h2>Data List</h2>
          <table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Name</th>
                <th>Value</th>
                <th>Unit</th>
              </tr>
            </thead>
            <tbody>
              {data.dataList.map((item, index) => (
                <tr key={index}>
                  <td>{item.key}</td>
                  <td>{item.name}</td>
                  <td>{item.value}</td>
                  <td>{item.unit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default SolarSofar;
