import React, { useState, useEffect } from "react";
import axios from "axios";
import { sha224 } from "js-sha256";

const Fronius = () => {
  const [access_token, setAccessToken] = useState();

  useEffect(() => {
    loginFroniusAPI();
  }, []);

  const loginFroniusAPI = () => {
    const postData = {
      AccessKeyId: "FKIAFEF58CFEFA94486F9C804CF6077A01AB",
      AccessKeyValue: "47c076bc-23e5-4949-37a6-4bcfcf8d21d6",
    };

    axios
      .get(`https://api.solarweb.com/swqapi/pvsystems`, postData)
      .then((response) => {
        console.log(response.data);
        setAccessToken(response.data.access_token);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div className="Se">
      <header className="Se-header">Monitoring Fronius</header>
      <h4>Access Token : {access_token}</h4>
      <h4>{access_token ? "Api connected" : "Error"}</h4>
    </div>
  );
};

export default Fronius;
