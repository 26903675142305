import React from "react";

const SummaryProduction = ({ totalPeakPower }) => {
  return (
    <div className="box kw-box">
      <div className="savings-widget-title">Produkcja dziś</div>
      <div className="kw-value" style={{ color: "black" }}>
        {totalPeakPower ? parseFloat(totalPeakPower).toFixed(2) : 0} kW
      </div>
    </div>
  );
};
export default SummaryProduction;
