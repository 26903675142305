import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SolarEdge from "../pagesInterfaces/SolarEdge";
import Huawei from "../pagesInterfaces/Huawei";
import FoxEss from "../pagesInterfaces/FoxEss";
import { useNavigate } from "react-router-dom";

const InstallationDetails = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [siteDetails, setSiteDetails] = useState(); //SolarEdge

  return (
    <>
      <button onClick={() => navigate(-1)}>Powrót</button>
      <h1>Informacje o instalacji: {id}</h1>
      {/* Pobranie informacji o instalacji w oparciu o id */}
      {type == "solarEdge" ? <SolarEdge siteId={id} /> : null}
      {type == "huawei" ? <Huawei /> : null}
      {type == "foxEss" ? <FoxEss /> : null}
    </>
  );
};

export default InstallationDetails;
