import axios from "axios";

export const getSolarEdgePlants = (apiKey) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}api/solarEdge/sites?apiKey=${apiKey}`
  );
};

export const getSolarEdgeDetails = (apiKey, idSite) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}api/solarEdge/details?apiKey=${apiKey}&idSite=${idSite}`
  );
};

export const getPower = (apiKey, idSite) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}api/solarEdge/sitePower?apiKey=${apiKey}&idSite=${idSite}`
  );
};

export const getSolarEdgeEnergyFromTo = (apiKey, selectedSite, start, end) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}api/solarEdge/siteEnergy?selectedSite=${selectedSite}&apiKey=${apiKey}&startDate=${start}&endDate=${end}`
  );
};

export const getSolarEdgeEvents = (apiKey, idSite) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}api/solarEdge/getEvents?apiKey=${apiKey}&idSite=${idSite}`
  );
};

export const getSolarEdgeTechnicalData = (apiKey, selectedSite) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}api/solarEdge/getTechnicalData?selectedSite=${selectedSite}&apiKey=${apiKey}`
  );
};
