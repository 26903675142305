import React, { useEffect, useContext, useState } from "react";
import "../css/Dashboard.css";
import Chart from "react-apexcharts";

import EnergyProduction from "../components/EnergyProduction";
import SummaryProduction from "../components/SummaryProduction";
import InstalationsList from "../components/InstalationsList";

import { useUserData, UserDataContext } from "../context/UserDataContext";

const Dashboard = () => {
  const { userData, updateUserData } = useUserData();
  const [totalPeakPower, setTotalPeakPower] = useState(0);

  useEffect(() => {
    console.log(userData);
  }, []);

  // const getUserData = () => {
  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}user/data/${username}`)
  //     .then((response) => {});
  // };

  const chartData = {
    options: {},
    series: [44, 55, 41],
    labels: ["Goodwe", "SolarEdge", "FoxEss"],
  };

  return (
    <>
      <div className="dashboard">
        <EnergyProduction />
        <SummaryProduction totalPeakPower={totalPeakPower} />
        <div className="box chart-box">
          <div className="savings-widget-title">Statystyki</div>
          <div className="chart-placeholder">
            <div className="donut">
              <Chart
                options={chartData.options}
                series={chartData.series}
                hideLabels={false}
                type="donut"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
      <InstalationsList onTotalPeakPowerChange={setTotalPeakPower} />
    </>
  );
};

export default Dashboard;
