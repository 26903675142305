import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useUserData } from "../context/UserDataContext";
import {
  getSolarEdgePlants,
  getSolarEdgeDetails,
  getPower,
  getSolarEdgeEnergyFromTo,
  getSolarEdgeEvents,
  getSolarEdgeTechnicalData,
} from "../utils/solarEdgeUtils";

import Dropdown from "react-bootstrap/Dropdown";
// https://knowledge-center.solaredge.com/sites/kc/files/se_monitoring_api.pdf
// https://monitoringapi.solaredge.com/sites/list?api_key=ZAEN744AYRF7T31TL0XC4OIMOXFCZ324

const SolarEdge = ({ siteId }) => {
  const [info, setInfo] = useState(false);
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [siteDetails, setSiteDetails] = useState();
  const [sitePower, setSitePower] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [show, setShow] = useState(null);
  const [technicalData, setTechnicalData] = useState();
  const apiKey = localStorage.getItem("solarEdgeToken");
  const sample = [1, 10, 30, 50, 70, 90, 100];

  const { userData } = useUserData();
  console.log(userData);
  useEffect(() => {
    if (siteId) {
      setSelectedSite(siteId);
      selectForMoreDetails(siteId);
      getSitePower(siteId);
    }
    if (
      localStorage.getItem("solarEdgeToken") === null ||
      userData.solarEdgeToken === ""
    ) {
      setInfo(true);
    } else {
      callSeAPI();
    }
  }, []);

  let data = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: sitePower ? sitePower.map((item) => item.date) : null,
      },
    },
    series: [
      {
        name: "series-1",
        data: sitePower ? sitePower.map((item) => item.value) : null,
      },
    ],
  };

  const callSeAPI = () => {
    getSolarEdgePlants(apiKey)
      .then((response) => {
        console.log("SE", response);
        setSites(response.data.sites.site);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectForMoreDetails = (idSite) => {
    console.log("Selected For More Details", idSite);
    getSolarEdgeDetails(apiKey, idSite)
      .then(function (response) {
        console.log("Details", response.data.details);
        setSiteDetails(response.data.details);
        setShow(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSitePower = (idSite) => {
    console.log("SitePower front", idSite);
    getPower(apiKey, idSite)
      .then(function (response) {
        console.log("15 min data", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //URL: /site/{siteId}/energy to graph
  //https://monitoringapi.solaredge.com/site/1/energy?timeUnit=DAY&endDate=2013-05-30&startDate=2013-05-01api_key=L4QLVQ1LOKCQX2193VSEICXW61NP6B1O
  //Allowed values are: QUARTER_OF_AN_HOUR,HOUR, DAY, WEEK, MONTH, YEAR

  const getSiteEnergy = () => {
    let sD = startDate ? startDate : new Date();
    let eD = endDate ? endDate : new Date();
    let start = sD
      .toISOString()
      .replace("-", "-")
      .split("T")[0]
      .replace("-", "-");
    let end = eD
      .toISOString()
      .replace("-", "-")
      .split("T")[0]
      .replace("-", "-");
    getSolarEdgeEnergyFromTo(apiKey, selectedSite, start, end)
      .then(function (response) {
        console.log("Energy", response.data);
        setSitePower(response.data.energy.values);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const showErrors = (idSite) => {
    getSolarEdgeEvents(apiKey, idSite)
      .then(function (response) {
        console.log("Errors", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //https://monitoringapi.solaredge.com/equipment/2/list?api_key=L4QLVQ1LOKCQX2193VSEICXW61NP6B1O
  // Return a list of inverters/SMIs in the specific site.

  const getTechnicalData = () => {
    //https://monitoringapi.solaredge.com/equipment/2/12345678-90/data?startTime=2013-05-5%2011:00:00&endTime=2013-05-05%2013:00:00&api_key=L4QLVQ1LOKCQX2193VSEICXW61NP6B1O
    getSolarEdgeTechnicalData(apiKey, selectedSite)
      .then(function (response) {
        setTechnicalData(response.data);
        console.log("Technical Data", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const select = (eventKey) => {
    setSelectedSite(eventKey);
    selectForMoreDetails(eventKey);
    getSitePower(eventKey);
    showErrors(eventKey);
    console.log(eventKey);
  };

  const showSitesList = () => {
    return (
      <Dropdown onSelect={select}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Instalacje SolarEdge
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {sites.map((item) => (
            <Dropdown.Item
              eventKey={item.id}
              active={item.id === selectedSite}
              key={item.id}
            >
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className="Se">
      <header className="Se-header">
        {info && (
          <div class="alert alert-danger" role="alert">
            W celu połączenia z klientem SE podaj SolarEdge Token w zakładce
            Profil/ Solaredge i kliknij zapisz
          </div>
        )}
        Monitoring SolarEdge
        {showSitesList()}
        {show && (
          <>
            <div>{siteDetails.name}</div>
            <div>Dzisiejsza produkcja: {siteDetails.peakPower}kW</div>
            <div>Błędy: {siteDetails.alertQuantity}</div>
            <DatePicker
              selected={startDate ? startDate : new Date()}
              onChange={(date) => setStartDate(date)}
              dateFormat="MMMM d, yyyy"
            />
            <DatePicker
              selected={endDate ? endDate : new Date()}
              onChange={(date) => setEndDate(date)}
              dateFormat="MMMM d, yyyy"
            />
            <button onClick={() => getSiteEnergy()}>Pokaz wykres</button>
            {sitePower && (
              <Chart
                style={{ backgroundColor: "white" }}
                options={data.options}
                series={data.series}
                type="line"
                width="500"
              />
            )}
            <button
              title="Pokarz dane o instalacji"
              onClick={() => getTechnicalData()}
            >
              Pokaz dane instalacji
            </button>
            <div>
              Falownik:{" "}
              {technicalData != null
                ? technicalData.reporters.list[0].model
                : null}
              <br />
              Numer Seryjny:{" "}
              {technicalData != null
                ? technicalData.reporters.list[0].serialNumber
                : null}
            </div>

            <div>Błędy instalacji:</div>
          </>
        )}
        <a
          onClick={() => {
            getSitePower();
          }}
        ></a>
      </header>
    </div>
  );
};

export default SolarEdge;
