import React from "react";
import growatt from "../growatt/lib-growatt";

import "../App.css";

const Growatt = () => {
  const config = {
    indexCandI: true,
    // Configuration options
  };

  const growattInstance = growatt(config);

  if (typeof growattInstance.login === "function") {
    growattInstance
      .login("test3k", "Kopytko1!")
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    console.error("The login function is not defined in the module.");
  }

  return (
    <div className="Growatt">
      <header className="Growatt-header">Growatt monitoring</header>
    </div>
  );
};

export default Growatt;
