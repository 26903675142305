import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import axios from "axios";

import AuthProvider, { useAuth } from "../context/AuthContext";
import { useUserData, UserDataContext } from "../context/UserDataContext";

import "./App.css";
import LoadingSpinner from "../components/LoadingSpinner";

const Profil = () => {
  const { userData } = useUserData();

  useEffect(() => {
    console.log(userData);
    setSolarEdgeToken(userData.solarEdgeToken || "");
    setGoodweEmail(userData.goodweEmail || "");
    setGoodwePassword(userData.goodwePassword || "");
    setGoodwePowerStationId(userData.goodwePowerStationId || "");
    setFoxessKey(userData.foxessKey || "");
    setGrowattUser(userData.growattUser || "");
    setGrowattPassword(userData.growattPassword || "");
    setSolarmanToken(userData.solarmanToken || "");
    setSolarmanEmail(userData.solarmanEmail || "");
    setSolarmanPassword(userData.solarmanPassword || "");
    setFusionSolarUser(userData.fusionSolarUser || "");
    setFusionSolarPassword(userData.fusionSolarPassword || "");
  }, []);

  const { isAuthenticated } = useAuth();
  const [solarEdgeToken, setSolarEdgeToken] = useState("");
  const [goodweEmail, setGoodweEmail] = useState("");
  const [goodwePassword, setGoodwePassword] = useState("");
  const [goodwePowerStationId, setGoodwePowerStationId] = useState("");
  const [foxessKey, setFoxessKey] = useState("");
  const [growattUser, setGrowattUser] = useState("");
  const [growattPassword, setGrowattPassword] = useState("");
  const [solarmanToken, setSolarmanToken] = useState("");
  const [solarmanEmail, setSolarmanEmail] = useState("");
  const [solarmanPassword, setSolarmanPassword] = useState("");
  const [fusionSolarUser, setFusionSolarUser] = useState("");
  const [fusionSolarPassword, setFusionSolarPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const sendForm = (event) => {
    event.preventDefault();
    setLoading(true);
    localStorage.setItem("solarEdgeToken", solarEdgeToken);
    localStorage.setItem("solarmanToken", solarmanToken);
    localStorage.setItem("solarmanEmail", solarmanEmail);
    localStorage.setItem("solarmanPassword", solarmanPassword);
    localStorage.setItem("goodweEmail", goodweEmail);
    localStorage.setItem("goodwePassword", goodwePassword);
    localStorage.setItem("goodwePowerStationId", goodwePowerStationId);
    localStorage.setItem("foxessKey", foxessKey);
    localStorage.setItem("growattUser", growattUser);
    localStorage.setItem("growattPassword", growattPassword);
    localStorage.setItem("fusionSolarUser", fusionSolarUser);
    localStorage.setItem("fusionSolarPassword", fusionSolarPassword);

    const username = localStorage.getItem("username");
    axios
      .put(`${process.env.REACT_APP_API_URL}updateSettings/${username}`, {
        solarEdge_token: solarEdgeToken,
        solarman_token: solarmanToken,
        solarman_email: solarmanEmail,
        solarman_password: solarmanPassword,
        goodwe_account_email: goodweEmail,
        goodwe_password: goodwePassword,
        goodwe_powerStationId: goodwePowerStationId,
        foxees_token: foxessKey,
        growatt_user: growattUser,
        growatt_password: growattPassword,
        fusionSolar_user: fusionSolarUser,
        fusionSolar_password: fusionSolarPassword,
      })
      .then((response) => {
        console.log(response.data);
        alert(JSON.stringify(response.data.message));
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };

  return (
    <AuthProvider>
      <div id="profil">
        <LoadingSpinner loading={loading} />
        <h1>Profil</h1>
        <p>Witaj {isAuthenticated ? "użytkowniku" : "nieznajomy"}</p>
        <form onSubmit={sendForm}>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Dane uzytkownika</Accordion.Header>
              <Accordion.Body>
                <h4>Dane uzytkownia</h4>
                <div
                  className="form-group"
                  style={{ marginBottom: 10, width: 500 }}
                >
                  <label htmlFor="usernameInput">Username</label>
                  <input
                    type="text"
                    value={userData.username}
                    className="form-control"
                    id="usernameInput"
                    sugestion="username"
                    placeholder="Enter username"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>SolarEdge</Accordion.Header>
              <Accordion.Body>
                <div className="form-group" style={{ width: 500 }}>
                  <h4>Dane do kont zewnętrznych</h4>
                  <label htmlFor="solarEdgeInput">SolarEdge Token</label>
                  <input
                    type="text"
                    value={solarEdgeToken}
                    className="form-control"
                    sugestion="SolarEdge Token"
                    onChange={(e) => setSolarEdgeToken(e.target.value)}
                    id="solarEdgeInput"
                    placeholder="Enter SolarEdge Token"
                  />
                  <small id="usernameHelp" className="form-text text-muted">
                    {
                      "To generate an account users API key:In the Account Admin > Company Details tab > API Access section:"
                    }
                    {
                      "To generate a Site API key: In the Site Admin > Site Access tab > Access Control tab > API Access section: 1 Acknowledge reading and agreeing to the SolarEdge API Terms & Conditions. 2 Click Generate API key.3 Copy the key.4 Click Save.5 Use the key in all API requests"
                    }
                    {
                      "Tutaj demo klucz z naszych zaobów ZAEN744AYRF7T31TL0XC4OIMOXFCZ324"
                    }
                  </small>
                  <br />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Solar Sofar - SolarMan</Accordion.Header>
              <Accordion.Body>
                <div
                  className="form-group"
                  style={{ marginTop: 20, marginBottom: 10, width: 500 }}
                >
                  <label htmlFor="solarmanTokenInput">Solarman Pv Token</label>
                  <input
                    type="text"
                    value={solarmanToken}
                    onChange={(e) => setSolarmanToken(e.target.value)}
                    className="form-control"
                    id="solarmanTokenInput"
                    placeholder="Enter Solarman Token"
                  />
                  <label htmlFor="solarmanTokenInput">
                    Solarman Pv user email
                  </label>
                  <input
                    type="text"
                    value={solarmanEmail}
                    onChange={(e) => setSolarmanEmail(e.target.value)}
                    className="form-control"
                    id="solarmanEmailInput"
                    placeholder="Enter Solarman Login Email"
                  />
                  <label htmlFor="solarmanPasswordInput">
                    Solarman Pv user password
                  </label>
                  <input
                    type="password"
                    value={solarmanPassword}
                    onChange={(e) => setSolarmanPassword(e.target.value)}
                    className="form-control"
                    id="solarmanPasswordInput"
                    placeholder="Enter Solarman Login Password"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Goodwe</Accordion.Header>
              <Accordion.Body>
                <div
                  className="form-group"
                  style={{ marginTop: 20, marginBottom: 10, width: 500 }}
                >
                  <label htmlFor="goodweEmailInput">Account - email</label>
                  <input
                    type="text"
                    value={goodweEmail}
                    className="form-control"
                    onChange={(e) => setGoodweEmail(e.target.value)}
                    id="emailGoodweInput"
                    placeholder="Enter Goodwe user email"
                  />
                  <label htmlFor="goodwePasswordnInput">Goodwe - hasło</label>
                  <input
                    type="password"
                    value={goodwePassword}
                    onChange={(e) => setGoodwePassword(e.target.value)}
                    className="form-control"
                    id="passwordGoodweInput"
                    placeholder="Enter Goodwe user password"
                  />
                  <label htmlFor="goodwePowerStationIdInput">
                    Goodwe - powerStationId
                  </label>
                  <input
                    type="text"
                    value={goodwePowerStationId}
                    className="form-control"
                    onChange={(e) => setGoodwePowerStationId(e.target.value)}
                    id="powerStationId"
                    placeholder="Enter Goodwe powerStationId"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>FoxEss Cloud</Accordion.Header>
              <Accordion.Body>
                <div
                  className="form-group"
                  style={{ marginTop: 20, marginBottom: 10, width: 500 }}
                >
                  <label htmlFor="goodweEmailInput">FoxEss token</label>
                  <input
                    type="text"
                    value={foxessKey}
                    className="form-control"
                    onChange={(e) => setFoxessKey(e.target.value)}
                    id="foxessKeyInput"
                    placeholder="Enter FoxEss token"
                  />
                  <small id="usernameHelp" className="form-text text-muted">
                    {
                      "Do wygenerowania klucza API należy zalogować się na stronie FoxEss Cloud, wejść w zakładkę Ustawienia i wygenerować klucz API. https://www.foxesscloud.com/user/center"
                    }
                  </small>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Growatt</Accordion.Header>
              <Accordion.Body>
                <div
                  className="form-group"
                  style={{ marginTop: 20, marginBottom: 10, width: 500 }}
                >
                  <label htmlFor="growattUser">User</label>
                  <input
                    type="text"
                    value={growattUser}
                    className="form-control"
                    onChange={(e) => setGrowattUser(e.target.value)}
                    id="growattUserInput"
                    placeholder="Enter Growatt user"
                  />
                  <label htmlFor="growattPassword">Growatt - hasło</label>
                  <input
                    type="password"
                    value={growattPassword}
                    onChange={(e) => setGrowattPassword(e.target.value)}
                    className="form-control"
                    id="growattPasswordInput"
                    placeholder="Enter Growatt password"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Huawei - Fusionsolar</Accordion.Header>
              <Accordion.Body>
                <div
                  className="form-group"
                  style={{ marginTop: 20, marginBottom: 10, width: 500 }}
                >
                  <label htmlFor="growattUser">Fusionsolar User</label>
                  <input
                    type="text"
                    value={fusionSolarUser}
                    className="form-control"
                    onChange={(e) => setFusionSolarUser(e.target.value)}
                    id="fusionsolarUserInput"
                    placeholder="Enter Fusionsolar user"
                  />
                  <label htmlFor="growattPassword">Fusionsolar Password</label>
                  <input
                    type="password"
                    value={fusionSolarPassword}
                    onChange={(e) => setFusionSolarPassword(e.target.value)}
                    className="form-control"
                    id="fusionsolarPasswordInput"
                    placeholder="Enter Fusionsolar password"
                  />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <button className="btn btn-primary" style={{ marginTop: 20 }}>
            Save
          </button>
        </form>
      </div>
    </AuthProvider>
  );
};

export default Profil;
