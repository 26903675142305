import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { useUserData } from "./UserDataContext";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const { userData, updateUserData } = useUserData();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = (username, password) => {
    return new Promise((resolve, reject) => {
      if (username === "" || password === "") {
        reject("Username or password cannot be empty.");
        return;
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}login`,
          {
            email: username,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          fetchUserData(response.data.userId);
          updateUserData({ username: username, id: response.data.userId });
          const token = "your_generated_token";
          localStorage.setItem("token", token);
          setIsAuthenticated(true);
          resolve(); // Zalogowanie się powiodło
        })
        .catch(function (error) {
          setIsAuthenticated(false);
          reject("Login failed. Please check your credentials."); // Błąd logowania
        });
    });
  };

  const fetchUserData = (user) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}usersGET/${user}`)
      .then((response) => {
        updateUserData({
          id: response.data.id,
          username: response.data.username,
          foxessKey: response.data.foxees_token,
          fusionSolarPassword: response.data.fusionSolar_password,
          fusionSolarUser: response.data.fusionSolar_user,
          goodweEmail: response.data.goodwe_account_email,
          goodwePassword: response.data.goodwe_password,
          goodwePowerStationId: response.data.goodwe_powerStationId,
          growattPassword: response.data.growatt_password,
          growattUser: response.data.growatt_user,
          solarEdgeToken: response.data.solarEdge_token,
          solarmanEmail: response.data.solarman_email,
          solarmanPassword: response.data.solarman_password,
          solarmanToken: response.data.solarman_token,
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, handleLogin, handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
