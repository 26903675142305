import React from "react";
import Chart from "react-apexcharts";

const lineData = {
  series: [
    {
      name: "Desktops",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    title: {
      text: "Product Trends by Month",
      align: "left",
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  },
};
const EnergyProduction = () => {
  return (
    <div className="box graph-box">
      <div className="savings-widget-title">Produkcja przez ostatni czas</div>
      <div className="graph-placeholder" style={{ color: "black" }}>
        Funkcja dostępna wkrótce
        {/* <Chart
          legend={lineData.legend}
          series={lineData.series}
          options={lineData.options}
          type="line"
          height="100%"
        /> */}
      </div>
    </div>
  );
};

export default EnergyProduction;
