import axios from "axios";

export const getPlantListFoxess = async (key) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}api/foxess/plant/list`
  );
};

export const plantDetailsFoxess = async (plantID) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}api/foxess/plant/details?plantID=${plantID}`
  );
};

export const getPlantPowerGenerationsFoxess = async (sn) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL}api/foxess/plant/powerGeneration?sn=${sn}`
  );
};

export const getPlantHistory = async (sn) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}api/foxess/plant/history?sn=${sn}`
  );
};

export const getPlantRealTimeData = async (sn, startDate, endDate) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}api/foxess/plant/realData?sn=${sn}`
  );
};
