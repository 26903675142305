import React, { useState, useEffect } from "react";
import axios from "axios";
import "../css/Huawei.css";
import {
  fusionSolarSetupCredentials,
  fusionSolarGetStationList,
  fusionSolarPlantDetails,
  fusionSolarHistoryData,
} from "../utils/huaweiUtils";

const Huawei = () => {
  useEffect(() => {
    callHuaweiAPI();
  }, []);
  const [stations, setStations] = useState([]);
  const [plantDetails, setPlantDetails] = useState([]);
  const [cookie, setCookie] = useState(null);

  const callHuaweiAPI = async () => {
    try {
      const response = await fusionSolarSetupCredentials();
      console.log("cookie", response.data.token);
      let cookie = response.data.token;
      setCookie(response.data.token);
      if (response.data.token != null) {
        try {
          const response = await fusionSolarGetStationList(cookie);
          console.log("Get Station List", response.data);
          setStations(response.data.data.list);
        } catch (error) {
          console.error("There was an error!", error);
        }
      }
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const getPlantDetails = async (cookie, plantCode) => {
    console.log("HUA", callHuaweiAPI, cookie, plantCode);
    try {
      // setLoading(true);
      let response = await fusionSolarPlantDetails(cookie, plantCode);
      setPlantDetails(response.data.data);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  const getPlantHistoryData = async (cookie, plantCode) => {
    try {
      let response = await fusionSolarHistoryData(cookie, plantCode);
      console.log("History", response);
    } catch (error) {
      console.error("There was an error!", error);
    }
  };

  return (
    <div id="Huawei">
      <header className="Se-header">Monitoring Huawei</header>
      <div id="details">
        <table
          style={{
            marginTop: "20px",
            border: "2px solid #000",
            backgroundColor: "#f0f0f0",
            width: "100%",
            textAlign: "left",
            fontSize: "18px",
          }}
        >
          <thead>
            <tr style={{ fontWeight: "bold", backgroundColor: "#ddd" }}>
              <th style={{ padding: "10px" }}>Kod instalacji</th>
              <th style={{ padding: "10px" }}>Moc całkowita</th>
              <th style={{ padding: "10px" }}>Produkcja w ciągu dnia</th>
              <th style={{ padding: "10px" }}>Produkcja miesięczna</th>
              <th style={{ padding: "10px" }}>Akcje</th>
            </tr>
          </thead>
          <tbody>
            {plantDetails &&
              plantDetails.map((plant, index) => (
                <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
                  <td style={{ padding: "10px" }}>{plant.stationCode}</td>
                  <td style={{ padding: "10px" }}>
                    {plant.dataItemMap.total_power}
                  </td>
                  <td style={{ padding: "10px" }}>
                    {plant.dataItemMap.day_power}
                  </td>
                  <td style={{ padding: "10px" }}>
                    {plant.dataItemMap.month_power}
                  </td>
                  <td style={{ padding: "10px" }}>
                    <button
                      style={{
                        padding: "5px 10px",
                        backgroundColor: "#007bff",
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        getPlantHistoryData(cookie, plant.stationCode)
                      }
                    >
                      Produkcja
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <table
        style={{
          marginTop: "20px",
          width: "100%",
          textAlign: "left",
        }}
      >
        <thead>
          <tr style={{ fontWeight: "bold", backgroundColor: "#ddd" }}>
            <th style={{ padding: "10px" }}>Name</th>
            <th style={{ padding: "10px" }}>Plant Code</th>
            <th style={{ padding: "10px" }}>Capacity</th>
            <th style={{ padding: "10px" }}>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {stations.map((station, index) => (
            <tr key={index} style={{ borderBottom: "1px solid #ccc" }}>
              <td style={{ padding: "10px" }}>{station.plantName}</td>
              <td style={{ padding: "10px" }}>{station.plantCode}</td>
              <td style={{ padding: "10px" }}>{station.capacity}</td>
              <td style={{ padding: "10px" }}>
                <button
                  style={{
                    padding: "5px 10px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => getPlantDetails(cookie, station.plantCode)}
                >
                  Więcej informacji
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Huawei;
